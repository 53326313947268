<template>
    <v-footer class="main">
        <v-col
                cols="12"
                class="text-center grey--text">
            {{new Date().getFullYear()}} — <strong>ARSconnect </strong>
            <span class="text-caption">
                  (Beta)
              </span>
        </v-col>
    </v-footer>
</template>

<script>
    export default {
      name: 'dasfooter'
    }
</script>