<template>
  <v-row class="justify-center">
    <v-col cols="10">
      <v-card flat class="pa-5">
        <h1>Privacy Policy</h1>

        <h5>Last Updated: December 07,2021</h5>
        <br />
        <span>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You. <br />
          <br />
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy. This Privacy Policy has been
          created with the help of the Privacy Policy Generator.
        </span>
        <br />
        <br />
        <h2>Interpretation and Definitions</h2>
        <br />
        <span>
          <h4>Interpretation</h4>
          <br />
          <span>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </span>
          <br />
          <br />
          <h4>Definitions</h4>
          <br />
          <span>For the purposes of this Privacy Policy:</span> <br /><br />
          <span class="tab1">
            <b>Account</b> means a unique account created for You to access our
            Service or parts of our Service. <br />
            <b>Company</b> (referred to as either "the Company", "We", "Us" or
            "Our" in this Agreement) refers to Advanced Robot Solutions LLC, 60
            Watson Boulevard, Suite 1, Stratford CT 06615.<br />
            <b>Cookies</b> are small files that are placed on Your computer,
            mobile device or any other device by a website, containing the
            details of Your browsing history on that website among its many
            uses. <br />
            <b>Country</b> refers to: Connecticut, United States <br /><b
              >Device</b
            >
            means any device that can access the Service such as a computer, a
            cellphone or a digital tablet. <br /><b>Personal Data</b> is any
            information that relates to an identified or identifiable
            individual.<br />
            <b>Service</b> refers to the Website.<br />
            <b>Service Provider</b> means any natural or legal person who
            processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used. <br /><b
              >Usage Data</b
            >
            refers to data collected automatically, either generated by the use
            of the Service or from the Service infrastructure itself (for
            example, the duration of a page visit). <br /><b> Website</b> refers
            to nm-dashboard, accessible from https://nm-dashboard.arsconnect.com
            <br /><b>You</b> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </span>
        </span>
        <br />
        <br />
        <h2>Collecting and Using Your Personal Data</h2>
        <br />
        <span>
          <h3>Types of Data Collected</h3>
          <br />
          <h4>Personal Data</h4>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to: <br /><br />
          <span class="tab1">
            Email address<br />
            First name and last name<br />
            Usage Data
          </span>
          <br /><br />
          <h4>Usage Data</h4>
          Usage Data is collected automatically when using the Service.
          <br /><br />
          <p>
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </p>
          <p>
            You access the Service by or through a mobile device, We may collect
            certain information automatically, including, but not limited to,
            the type of mobile device You use, Your mobile device unique ID, the
            IP address of Your mobile device, Your mobile operating system, the
            type of mobile Internet browser You use, unique device identifiers
            and other diagnostic data.
          </p>
          <p>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
        </span>
        <span>
          <h4>
            Link to privacy policy of third-party service providers used by the
            app
          </h4>
          <span class="tab1"
            ><br /><a href="https://firebase.google.com/">Firebase</a><br /><a
              >Google Analytics for Firebase</a
            ><br /><a>Digital Ocean</a> </span
          ><br /><br />

          <h4>Tracking Technologies and Cookies</h4>
          <p>No tracking technologies and cookies are used.</p>
          <h4>Use of Your Personal Data</h4>
          The Company may use Personal Data for the following purposes:<br /><br />
          <span class="tab1">
            <b>To provide and maintain our Service</b>, including to monitor the
            usage of our Service.<br /><b> To manage Your Account:</b> to manage
            Your registration as a user of the Service. The Personal Data You
            provide can give You access to different functionalities of the
            Service that are available to You as a registered user. <br /><b
              >For the performance of a contract:</b
            >
            the development, compliance and undertaking of the purchase contract
            for the products, items or services You have purchased or of any
            other contract with Us through the Service. <br /><b
              >To contact You:</b
            >
            To contact You by email, telephone calls, SMS, or other equivalent
            <br /><b>To manage Your requests: </b> To attend and manage Your
            requests to Us.<br /><b> For other purposes: </b> We may use Your
            information for other purposes, such as data analysis, identifying
            usage trends, determining the effectiveness of our promotional
            campaigns and to evaluate and improve our Service, products,
            services, marketing and your experience.</span
          >
          <p>
            We may share Your personal information in the following situations:
          </p>
          <p>
            <br />
            <b>With Service Providers:</b> We may share Your personal
            information with Service Providers to monitor and analyze the use of
            our Service, to contact You. <br /><b>For business transfers:</b> We
            may share or transfer Your personal information in connection with,
            or during negotiations of, any merger, sale of Company assets,
            financing, or acquisition of all or a portion of Our business to
            another company.<br /><b>With Affiliates:</b> We may share Your
            information with Our affiliates, in which case we will require those
            affiliates to honor this Privacy Policy. Affiliates include Our
            parent company and any other subsidiaries, joint venture partners or
            other companies that We control or that are under common control
            with Us. <br /><b>With business partners:</b> We may share Your
            information with Our business partners to offer You certain
            products, services or promotions. <br /><b>With other users:</b>
            when You share personal information or otherwise interact in the
            public areas with other users, such information may be viewed by all
            users and may be publicly distributed outside. <br /><b>
              With Your consent:</b
            >
            We may disclose Your personal information for any other purpose with
            Your consent.<br /><br />
          </p>

          <h4>Retention of Your Personal Data</h4>
          <p>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p>
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
          <h4>Transfer of Your Personal Data</h4>
          <p>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>
          <p>
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p>
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>
          <h3>Disclosure of Your Personal Data</h3>
          <br />
          <b>Business Transactions</b>
          <p>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <b>Law enforcement</b>
          <p>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <b>Other legal requirements</b>
          <p>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <b>•</b> Comply with a legal obligation <br /><b>•</b> Protect and
          defend the rights or property of the Company <br /><b>•</b> Prevent or
          investigate possible wrongdoing in connection with the Service<br />
          <b>•</b> Protect the personal safety of Users of the Service or the
          public<br />
          <b>•</b> Protect against legal liability <br /><br />

          <h4>Security of Your Personal Data</h4>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
          <br />
          <br />

          <h2>Children's Privacy</h2>
          <br />
          <p>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </p>
          <p>
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>
          <br />
          <h2>Links to Other Websites</h2>
          <br />
          <span>
            Our Service does not contain links to other websites that are not
            operated by Us.</span
          >
          <br /><br />
          <h2>Changes to this Privacy Policy</h2>
          <br />
          <p>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </p>
          <p>
            are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>

          <p></p>
          <h2>Contact Us</h2>
          <br />
          If you have any questions about this Privacy Policy, You can contact
          us: <br />By email:
          <a href="mailto: info@arsconnect.com">info@arsconnect.com</a>
        </span>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "privacyPolicy",
};
</script>
<style>
.tab1 {
  display: inline-block;
  margin-left: 40px;
}
</style>