<template>
  <v-navigation-drawer :width="drawerWidth" color="white" clipped app>
    <v-app-bar flat>
      <v-img
        src="@/assets/logo.png"
        contain
        justify="center"
        max-height="50"
        max-width="200"
        class="my-auto"
      >
      </v-img>
    </v-app-bar>
    <v-navigation-drawer color="primary" permanent width="60" mini-variant dark>
      <h1>general</h1>
    </v-navigation-drawer>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "generalDrawer",
  data() {
    return {
      selectedItem: 1,
    };
  },
  computed: {
    drawerWidth() {
      return !this.selectedItem ? 64 : 300;
    },
  },
};
</script>