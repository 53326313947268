<template>
    <div>
        <navBar></navBar>
        <adminDrawer v-if="userProfile.data().userRole === 'Admin'" ></adminDrawer>
        <generalDrawer v-else></generalDrawer>
        
    </div>
</template>

<script>

    import navBar from './navBar/navBar';
    import adminDrawer from './navDrawer/admin/adminDrawer'
    import generalDrawer from './navDrawer/general/generalDrawer'
    
    import {mapGetters} from 'vuex';


    export default {
      name: 'navigation',
      components:{
        navBar,
        adminDrawer,
        generalDrawer,
        
      },
      computed:{
        ...mapGetters(['userProfile'])
      }

    }
</script>