import * as fb from '../../firebase/index.js'

export default {
    state: {
        userGroups: []
    },
    getters: {
        userGroups: state => state.userGroups,
    },
    mutations: {
        setUserGroups(state, userGroups) {
            state.userGroups = userGroups;
        }

    },
    actions: {
        getUserGroups({ commit }) {
            let groups = [];
            return new Promise((resolve, reject) => {
                fb.userGroupsCollection.get()
                    .then(querySnapshot => {
                        if (querySnapshot.empty) resolve(null);
                        querySnapshot.forEach(group => {
                            groups.push({ data: group.data(), id: group.id });
                        });
                        commit('setUserGroups', groups);
                        resolve("Feteched all user groups")
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },
        addNewGroup({ dispatch }, group) {
            return new Promise((resolve, reject) => {
                const newGroup = {
                    name: group.name,
                    description: group.description,
                    dateCreated: new Date(),
                    lastUpdated: new Date(),
                    userCount: 0,
                };
                fb.userGroupsCollection.add(newGroup)
                    .then(() => {
                        dispatch('getUserGroups');
                        resolve('New group added successfully')
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },
        updateGroup({ dispatch }, group) {
            return new Promise((resolve, reject) => {
                fb.userGroupsCollection.doc(group.id).update({
                    name: group.name,
                    description: group.description,
                    lastUpdated: new Date(),
                })
                    .then(() => {
                        dispatch('getUserGroups');
                        resolve('User group updated successfully.');
                    })
                    .catch(error => {
                        reject(error.message)
                    })
            })

        },
        deleteGroup({ dispatch }, groupId) {
            return new Promise((resolve, reject) => {
              fb.userGroupsCollection.doc(groupId).delete()
                .then(() => {
                  dispatch('getUserGroups');
                  resolve('Group deleted successfully.')
                })
                .catch(error => {
                  reject(error.message);
                })
            })
          }

    }
}