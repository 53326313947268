<template>
  <v-app class="main">
    <navigation v-if="showNav"> </navigation>
    <v-main>
      <router-view v-if="showContent"> </router-view>
      <loading v-else> </loading>
    </v-main>
    <dashFooter></dashFooter>
    <snackBar></snackBar>
    <announcement></announcement>
    <view-event></view-event>
  </v-app>
</template>

<script>
import snackBar from "@/components/notifications/snackBar.vue";
import announcement from "@/components/notifications/announcement.vue";
import navigation from "@/components/navigation/navigation.vue";
import calendarDrawer from "@/components/calendar/calendarDrawer.vue";
import loading from "@/components/loading.vue";
import dashFooter from "@/components/footer.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import ViewEvent from "./components/scheduling/viewEvent.vue";

export default {
  name: "App",
  components: {
    snackBar,
    navigation,
    loading,
    dashFooter,
    announcement,
    calendarDrawer,
    ViewEvent,
  },
  data() {
    return {
      showContent: false,
    };
  },
  computed: {
    ...mapGetters(["userProfile", "appData"]),
    showNav() {
      return Object.keys(this.userProfile).length > 1;
    },
  },
  beforeCreate() {
    this.$store.dispatch("getAppData").then(() => {
      if (this.appData.initiated) {
        this.$vuetify.theme.themes.light = this.appData.themes.light;
      }
      this.showContent = true;
    });
  },
  mounted() {
    this.$store.commit("setApi", window.gapi);
  },
};
</script>

<style>
.main {
  background-color: #f5f5f5 !important;
}
body::-webkit-scrollbar {
  display: none;
}
.v-card::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}
.v-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #6b1530;
}
.v-data-table::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}
.v-data-table::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #6b1530;
}
/* .v-virtual-scroll::-webkit-scrollbar { */
/* -webkit-appearance: none; */
/* color: #6b1530; */
/* width: 10px; */
/* } */
/* .v-virtual-scroll::-webkit-scrollbar-thumb { */
/* border-radius: 2px; */
/* background-color: rgba(0, 0, 0, .5); */
/* -webkit-box-shadow: 0 0 1px #6b1530; */
/* } */
</style>
