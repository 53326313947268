import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'
import Login from '@/views/Login.vue'
import notFound from '@/views/notFound.vue'
import policy from '@/views/privacyPolicy'
import {auth} from "@/firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    name: 'Not Found',
    component: notFound,
    meta:{
      title: '404'
    }
  },
  {
    path: '/kioskiFrame',
    name: 'kioskiFrame',
    component: () => import('@/views/kioskiFrame.vue'),
    meta:{
      title: 'Kiosk iFrame'
    }
  },
  {
    path: '/',
    // name: 'Dashboard',
    component: Dashboard,
    meta:{
      requiresAuth: true,
    },
    children:[
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/home.vue'),
        meta:{
          title: 'Home'
        }
      },
      {
        path: '/userManagement',
        name: 'userManagement',
        component: () => import('@/views/admin/userManagement.vue'),
        meta:{
          title: 'User Management'
        }
      },
      {
        path: '/groupManagement',
        name: 'groupManagement',
        component: () => import('@/views/admin/groupManagement.vue'),
        meta:{
          title: 'Group Management'
        }
      },
      {
        path: '/roleManagement',
        name: 'roleManagement',
        component: () => import('@/views/admin/roleManagement.vue'),
        meta:{
          title: 'Role Management'
        }
      },
      {
        path: '/kioskManagement',
        name: 'kioskManagement',
        component: () => import('@/views/admin/kioskManagement.vue'),
        meta:{
          title: 'Kiosk Management'
        }
      },
      {
        path: '/kioskAnalytics',
        name: 'kioskAnalytics',
        component: () => import('@/views/admin/kioskAnalytics.vue'),
        meta:{
          title: 'Kiosk Analytics'
        }
      },
      {
        path: '/userAnalytics',
        name: 'userAnalytics',
        component: () => import('@/views/admin/userAnalytics.vue'),
        meta:{
          title: 'User Analytics'
        }
      },
      {
        path: '/createMeeting',
        name: 'createMeeting',
        component: () => import('@/components/meetings/createMeeting.vue'),
        meta:{
          title: 'Create Meeting'
        }
      },
      {
        path: '/manageMeetings',
        name: 'manageMeetings',
        component: () => import('@/components/meetings/manageMeetings.vue'),
        meta:{
          title: 'Manage Meetings'
        }
      },      
      {
        path: '/scheduling',
        name: 'scheduling',
        component: () => import('@/views/scheduling.vue'),
        meta:{
          title: 'Scheduling'
        }
      },     
     
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{
      requiresGuest: true,
      title: 'Login'
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/admin/Admin.vue'),
    meta:{
      requiresGuest: true,
      title: 'Admin'
    }
  },
  {
    path: '/registration/:id',
    name: 'Registration',
    component: () => import('../views/invitedUserRegistration'),
    meta:{
      requiresGuest: true,
      title: 'Registration'
    }
  },
  {
    path: '/kioskRegistration/:id',
    name: 'kioskRegistration',
    component: () => import('../views/invitedKioskRegistration.vue'),
    meta:{
      requiresGuest: true,
      title: 'Kiosk Registration'
    }
  },
  {
    path: '/connect/:id',
    name: 'claraIframe',
    component: () => import('../views/claraIframe.vue'),
    meta:{
      requiresGuest: true,
      title: 'Connect'
    }
  },
  {
    path: '/policy',
    name: 'policy',
    component: policy,
    meta:{
      requiresGuest: true,
      title: 'Privacy Policy'
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  if(requiresAuth && !auth.currentUser){
    next('/login');
  }
  else{
    next();
  }
})

router.afterEach((to, from) => {  
  const DEFAULT_TITLE = 'Dashboard';
  
  Vue.nextTick(() => {
      document.title = ` ${to.meta.title} | ${DEFAULT_TITLE} `;
  });
});

export default router
