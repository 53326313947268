import * as fb from "../../firebase";
import router from "../../router";
import axios from 'axios';
import crypto from 'crypto';
import { result } from "lodash";
import { format, parseISO } from "date-fns";
import { stat } from "fs";

export default {
  state: {
    moderatorMeetingList: [],
    participantMeetingList: [],
    interpreterMeetingList: [],
    localParticipantsList: [],
    meetingObject: {},
    claraURL: 'clara-dev.arsconnect.com'

  },
  getters: {
    getModeratorMeetingList: state => state.moderatorMeetingList,
    getParticipantMeetingList: state => state.participantMeetingList,
    getInterpreterMeetingList: state => state.interpreterMeetingList,
    getLocalParticipantsList: state => state.localParticipantsList,
    getMeetingObject: state => state.meetingObject

  },
  mutations: {
    setModeratorMeetingList(state, items) {
      state.moderatorMeetingList = items;
    },
    setParticipantMeetingList(state, items) {
      state.participantMeetingList = items;
    },
    setInterpreterMeetingList(state, items) {
      state.interpreterMeetingList = items;
    },
    setLocalParticipantsList(state, list) {
      state.localParticipantsList = list
    },
    setMeetingObject(state, obj) {
      state.meetingObject = obj
    }
  },
  actions: {
    createMeeting({ state, dispatch }, meeting) {
      console.log('Creating Meeting')
      return new Promise((response, reject) => {
        let moderators = [];
        let participants = [];
        let interpreters = [];
        state.localParticipantsList.forEach(item => {
          console.log('Parsing Participants List')
          if (item.meetingRole === 'Moderator') {
            moderators.push(item.id);
          }
          else if (item.meetingRole === 'Participant') {
            participants.push(item.id);
          }
          else if (item.meetingRole === 'Interpreter') {
            interpreters.push(item.id);
          }
        })
        
        console.log('Checking if Moderator exists')
        if (moderators.length === 0) {
          reject('Please add atlease one Moderator.')
          return
        }
        else if (meeting.addInterpretation && interpreters.length === 0) {
          reject('Please add atleast one Interpreter');
          return
        }
        else{
          console.log('dispatching AI API')
          dispatch('createMeetingLinks', meeting)
          .then(result => {
            console.log('Got response object from AI API:', result)                        
            let document = {              
              meetingCode: result.room_code,
              meetingUrl: state.claraURL,              
              guestToken: result.meeting_panther.token,
              moderatorToken: result.members[1].meeting_panther.token,
              createdOn: new Date(),
              updatedOn: new Date(),
              moderatorList: moderators,
              participantList: participants,
              interpreterList: interpreters,
              configuration: { ...meeting },
              participantsDetails: state.localParticipantsList
            };
            console.log("DOCUMENT:", document)

            fb.meetingsCollection.add(document)
              .then((meetingRef) => {
                response('Meeting created successfully');
              })
              .catch(error => {
                console.log(error.message);
                reject(error.message);
              })
          })
        }      
      })
    },
    updateMeeting({state}, meeting){
      return new Promise((response, reject) => {
        let moderators = [];
        let participants = [];
        let interpreters = [];
        state.localParticipantsList.forEach(item => {
          console.log('Parsing Participants List')
          if (item.meetingRole === 'Moderator') {
            moderators.push(item.id);
          }
          else if (item.meetingRole === 'Participant') {
            participants.push(item.id);
          }
          else if (item.meetingRole === 'Interpreter') {
            interpreters.push(item.id);
          }
        })

        console.log('Checking if Moderator exists')
        if (moderators.length === 0) {
          reject('Please add atlease one Moderator.')
          return
        }
        else if (meeting.addInterpretation && interpreters.length === 0) {
          reject('Please add atleast one Interpreter');
          return
        }
        else{
          let document = {    
            updatedOn: new Date(),         
            moderatorList: moderators,                       
            participantList: participants,
            interpreterList: interpreters,
            configuration: { ...meeting.config },
            participantsDetails: state.localParticipantsList
          };

          fb.meetingsCollection.doc(meeting.id).update(document)
              .then((meetingRef) => {
                response('Meeting updated successfully, please refresh the list.');
              })
              .catch(error => {
                console.log(error.message);
                reject(error.message);
              })
        }        
      })
    },
    fetchModeratorMeetingList({ commit }, userId) {
      return new Promise((response, reject) => {
        fb.meetingsCollection.where('moderatorList', 'array-contains', userId).get()
          .then(result => {
            if (!result.empty) {
              commit('setModeratorMeetingList', result.docs);
              response('Meeting list fetched successfully');
            }
            else {
              reject('Cannot find any meeting.')
            }
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    fetchParticipantMeetingList({ commit }, userId) {
      return new Promise((response, reject) => {
        fb.meetingsCollection.where('participantList', 'array-contains', userId).get()
          .then(result => {
            if (!result.empty) {
              commit('setParticipantMeetingList', result.docs);
              response('Meeting list fetched successfully');
            }
            else {
              reject('Cannot find any meeting.')
            }
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    fetchInterpreterMeetingList({ commit }, userId) {
      return new Promise((response, reject) => {
        fb.meetingsCollection.where('interpreterList', 'array-contains', userId).get()
          .then(result => {
            if (!result.empty) {
              commit('setInterpreterMeetingList', result.docs);
              response('Meeting list fetched successfully');
            }
            else {
              reject('Cannot find any meeting.')
            }
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    fetchMeeting({ state }, meeetingCode) {
      return new Promise((response, reject) => {
        fb.meetingsCollection.where('meetingCode', '==', meeetingCode).get()
          .then(result => {
            if (!result.empty) {
              console.log(result.docs[0].data())
              response(result.docs[0].data());
            }
            else {
              reject('Cannot find any meeting.')
            }
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    deleteMeeting({state}, meetingId){
      return new Promise((response, reject) => {
        fb.meetingsCollection.doc(meetingId).delete()
        .then(() => {
          response('Meeting Deleted Successfully, please refresh the list.');
        })
        .catch(error => {
          reject(error.message)
        })
      })

    },
    createMeetingLinks({ state, commit }, meeting) {
      return new Promise((resquest, reject) => {
        console.log('Inside Create Meeting Link')
        let meetingCode = crypto.randomBytes(50).toString('hex').slice(0, 11);

        var data = JSON.stringify({
          "settings": {
            "start_day": format(parseISO(meeting.startDate), "MM-dd-yyyy"),
            "end_day": format(parseISO(meeting.endDate), "MM-dd-yyyy"),
            "start_time": meeting.startTime,
            "end_time": meeting.endTime,
            "all_day": meeting.allDay
          },
          "interpreters": [],
          "languages": [
            meeting.defaultLanguage,
            meeting.targetLanguage,
          ],
          "notifications": [
            {
              "time": 1,
              "timeline": "Hours",
              "type": "Notification"
            }
          ],
          "services": {
            "chat": meeting.textMessage,
            "share_screen": meeting.screenSharing,
            "raise_hand": meeting.handRaise,
            "timer": meeting.addCountdownTimer
          },
          "participants": [],
          "timer": meeting.countdown,
          "room_name": meeting.meetingTitle,
          "room_code": meetingCode,
          "email_moderator": "t@t.t",
          "email_judge": ""
        });


        console.log("Requisting IA API with:", data)

        const parameter = {
          api: 'https://clara-api-dev.panthermediasystem.net/api/meeting',
          data: data
        }

        const config = {
          method: 'get',
          url: 'https://us-central1-connect-dashboard.cloudfunctions.net/apiV2/meeting/a763cd056f1b2405788443b7197e0708',
          params: parameter
        }
        axios(config)
          .then(result => {
            // console.log(result.data);
            resquest(result.data);
            commit('setMeetingObject', result.data);
          })
      })
    }
  }
}