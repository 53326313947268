<template>
    <v-app-bar
            app
            flat
            dark            
            color="navBarColor">        
        <v-spacer></v-spacer>
        <v-toolbar-items>
            <profileMenu></profileMenu>

        </v-toolbar-items>
    </v-app-bar>
</template>

<script>
    import profileMenu from './profileMenu'

  export default {
    name: 'navBar',
    components:{
      profileMenu
    },
    methods:{
      showThis(){
        this.$store.dispatch('showAnnouncement', {
          text: 'Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum ' +
            'Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum Lorun Ipsum '
        })
        console.log("Clicked")
      }
    }
  }
</script>