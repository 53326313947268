import * as fb from "../../firebase";
import router from "../../router";
import store from "../index";


export default {
  state: {
    invitedUserProfile: {},
    userProfile: {},
    userId: null,
    token: null,
  },
  getters: {
    userProfile: state => state.userProfile,
    invitedUserProfile: state => state.invitedUserProfile,
    userId: state => state.userId,
  },
  mutations: {
    setUserProfile(state, data) {
      state.userProfile = data;
    },
    setInvitedUserProfile(state, data) {
      state.invitedUserProfile = data;
    },
    setUserId(state, uid) {
      state.userId = uid;
    },
    setToken(state, token) {
      state.token = token
    }
  },
  actions: {
    googleLogin({ dispatch,getters }) {
        //dispatch('loadClient')








      const provider = new fb.firebase.auth.GoogleAuthProvider();
      // provider.setCustomParameters({
      //   hd: 'nmcourts.gov'
      // });
      provider.setCustomParameters({
        hd: 'arsconnect.com',
        login_hint : 'arsconnect.com'
      });

      provider.addScope("https://www.googleapis.com/auth/calendar.events")
      fb.firebase.auth().signInWithPopup(provider).then((result) => {
        var credential = result.credential;
        var token = credential.accessToken;
        var user = result.user;
        console.log('Token: ', result)
        console.log(`Credentials:${credential} Token:${token} Username:${user.displayName} User ID:${user.uid} `)
        console.log("USER PROFILE", fb.userCollection.doc(user.uid).get().then)
        fb.userCollection.doc(user.uid).get().then(doc => {
          if (!doc.exists) {
            //create user profile in usercollection
            fb.userCollection.doc(user.uid).set({
              name: user.displayName,
              email: user.email,
              userRole: 'default',
              createdOn: new Date(),
              onlineStatus: 'Unavailable'
            }).then(() => {
              // dispatch('fetchUserProfile', user);
              console.log('New user created with google oAuth')
              //dispatch('loadClient')
            })
          }
        })
      }).catch(err => {
        alert(err)
      })
    },
    login({ dispatch }, form) {
      return new Promise((resolve, reject) => {
        fb.auth.signInWithEmailAndPassword(form.email, form.password).then(cred => {
          console.log(`user logged In: ${cred.user.uid}`);
          //fetch user profile
          dispatch('fetchUserProfile', cred.user);
          resolve('Successfully Logged In');
          // By Shubham - To load Gapi for Calendar Integration - Still in testing
          dispatch('loadClient')
        })
          .catch(error => {
            reject(error.message)
            //Todo: add logging mechanism to be stored on firebase for each account
          })
      })
    },
    logout({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        //set user Unavailable
        dispatch('setUserUnavailable');
        fb.auth.signOut()
          .then(() => {
            commit('setUserProfile', {});
            commit('setUserId', null);
            router.push('/login');
            resolve('Successfully Logged Out')
            location.reload();
          })
          .catch(error => {
            reject(error.message);
            //Todo: add logging mechanism to be stored on firebase for each account
          })
      })
    },
    createAdminAccount({ dispatch }, form) {
      return new Promise((resolve, reject) => {
        fb.auth.createUserWithEmailAndPassword(form.email, form.password)
          .then(cred => {
            //create user profile in usercollection
            fb.userCollection.doc(cred.user.uid).set({
              name: form.name,
              email: form.email,
              userRole: form.userRole,
              createdOn: new Date(),
              onlineStatus: 'Unavailable'
            }).then(() => {
              dispatch('initiateAppData')
                .then(() => {
                  console.log('app initiated');
                  resolve('Account Created Successfully.');
                })
            })
          })
          .catch(error => {
            reject(error.message);
            //Todo: add logging mechanism to be stored on firebase for each account
          })
      })
    },
    fetchUserProfile({ commit, dispatch }, user) {
      //fetch user profile data
      fb.userCollection.doc(user.uid)
        .onSnapshot(userData => {
          //set userProfile in state
          commit('setUserProfile', userData);
          //set userId in state
          commit('setUserId', user.uid);
          //change route to dashboard
          if (router.currentRoute.path === '/login' || router.currentRoute.path === '/admin') {
            router.push('/')
          }
        });
    },
    inviteNewUser({ state }, user) {
      return new Promise((resolve, reject) => {
        console.log(state.userId);
        fb.invitedUsersCollection.add({
          name: user.name,
          email: user.email,
          userRole: user.userRole,
          assignedKiosks: user.assignedKiosks,
          fdn: user.fdn,
          createdOn: new Date(),
        }).then(() => {
          resolve('Invitation sent successfully.');
        })
          .catch(error => {
            reject(error.message);
          })

      })
    },
    getInvitedUserInformation({ commit }, uId) {
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection.doc(uId).get()
          .then(userData => {
            commit('setInvitedUserProfile', userData.data());
            resolve('Fetched User Information');
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    createAccount({ dispatch }, form) {
      return new Promise((resolve, reject) => {
        fb.auth.createUserWithEmailAndPassword(form.email, form.password)
          .then(cred => {
            //create user profile in usercollection
            fb.userCollection.doc(cred.user.uid).set({
              name: form.name,
              email: form.email,
              userRole: form.userRole,
              createdOn: new Date(),
              onlineStatus: 'Unavailable'
            }).then(() => {
              fb.invitedUsersCollection.doc(form.invitationId).delete()
                .then(() => {
                  fb.auth.signOut();
                  resolve('Account Created Successfully. Login to Continue');
                  router.push('/login');
                })
            })
          })
          .catch(error => {
            reject(error.message);
            //Todo: add logging mechanism to be stored on firebase for each account
          })
      })
    },
    setUserAvailable({ state }) {
      fb.userCollection.doc(state.userId).update({
        onlineStatus: 'Available',
      })
    },
    setUserBusy({ state }) {
      fb.userCollection.doc(state.userId).update({
        onlineStatus: 'Busy',
      })
    },
    setUserUnavailable({ state }) {
      fb.userCollection.doc(state.userId).update({
        onlineStatus: 'Unavailable',
      })
    },
    manageUserPresence({ state, dispatch }, user) {
      var userStatusDatabaseRef = fb.firebase.database().ref('/status/' + user.uid);

      var isOfflineForDatabase = {
        state: 'Unavailable',
        last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
      };

      // // By Shubham - To load Gapi for Calendar Integration - Still in testing
      // dispatch('loadClient')
      var isOnlineForDatabase = {
        state: 'Available',
        last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
      };

      fb.firebase.database().ref('.info/connected').on('value', function (snapshot) {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() === false) return;

        userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
          userStatusDatabaseRef.set(isOnlineForDatabase);
        });
      });
    },

  }
}