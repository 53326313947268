import * as fb from "../../firebase";
import moment from 'moment'
import auth from './auth'
export default {
    state: {
        api: undefined,
        calendarItems: [],
        event: {
            repeatEvent: "Does Not Repeat"
        },
        viewEvent: false,
        eventConfig: [],
        API_KEY: 'AIzaSyBVz6MWvfr9e7Ua20qLaK8bFF7AfVK-5Kk',
        CLIENT_ID: '342515181178-b9e1evh2hkilidlp1iv4fo0rcmnh0u1i.apps.googleusercontent.com',
        SCOPES: 'https://www.googleapis.com/auth/calendar'
    },
    mutations: {
        setApi(state, api) {
            state.api = api
        },
        setCalendarItems(state, calendarItemObj) {
            state.calendarItems.push(calendarItemObj)
        },
        setEvent(state, eventObj) {
            state.event = eventObj
        },
        setViewEvent(state, status) {
            state.viewEvent = status
        },
        setEventConfig(state, obj) {
            state.eventConfig = obj
        }
    },
    getters: {
        getApi: state => state.api,
        getCalenderItems: state => state.calendarItems,
        getEvent: state => state.event,
        getViewEvent: state => state.viewEvent,
        getEventConfig: state => state.eventConfig
    },
    actions: {
        loadClient({ getters, state, dispatch }) {
            var GoogleAuth;
            console.log('Getting calendar stuff loaded')
            getters.getApi.load("client:auth2", () => {
                console.log("loaded client");
                getters.getApi.client.init({
                    apiKey: state.API_KEY,
                    clientId: state.CLIENT_ID,
                    scope: state.SCOPES
                })
                
                getters.getApi.client
                    .load("https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest")
                    .then(response => {
                        console.log("Gapi Api Loaded", response);
                        
                    });
                // getters.getApi.auth2.getAuthInstance().signIn().then(response => {
                //     console.log(response)
                //     const token = response.vc.id_token;
                //     console.log(token)
                //     var credentials = fb.auth.GoogleAuthProvider.credential(response.vc.id_token)
                //     fb.auth().signInWithCredential(credentials)
                // })
                // console.log(googleUser)
                // const token = googleUser.getAuthResponse().id_token;


            })
        },
        updateSigninStatus(status) {
            console.log('updateSigninStatus', status)
        },
        pullCalendarItem({ dispatch, getters, state }) {
            state.calendarItems = []
            getters.getApi.client.calendar.events
                .list({
                    calendarId: "primary",
                    showDeleted: false,
                    singleEvents: true,
                    maxResults: 100,
                    orderBy: "startTime",
                    timeMin: new Date().toISOString(),
                })
                .then((response) => {
                    response.result.items.forEach((item) => {
                        //console.log(item)
                        dispatch("updateCalendarItems", item);
                    });

                });
        },
        updateCalendarItems({ commit, getters }, calendarObj) {
            console.log('calendarObj', calendarObj)
            var allDay, startD, endD, recurr, startT, endT, recurrDays = [], participants = [];
            let dayMap = new Map();
            dayMap.set("MO", "Monday");
            dayMap.set("TU", "Tuesday");
            dayMap.set("WE", "Wednesday");
            dayMap.set("TH", "Thrusday");
            dayMap.set("FR", "Friday");
            // Attendees Handling
            if('attendees' in calendarObj){
                if(calendarObj.attendees.length > 1){
                    calendarObj.attendees.forEach(attend => {
                        console.log(attend.email)
                        participants.push(attend.email)
                    })
                }else {
                    participants.push(calendarObj.attendees[0].email)
                }
            }
            


            if ('recurringEventId' in calendarObj) {
                getters.getApi.client.calendar.events.get({
                    "calendarId": "shubhamkashikar29@gmail.com",
                    "eventId": calendarObj.recurringEventId
                }).then(response => {
                    let recurranceFreq = response.result.recurrence[0].substring(response.result.recurrence[0].search("=") + 1, response.result.recurrence[0].search(";"))
                    if (recurranceFreq === "WEEKLY") {
                        recurr = "Weekly"
                        let days = response.result.recurrence[0].substring(response.result.recurrence[0].search("BYDAY") + 6, response.result.recurrence[0].length).split(",")
                        if (days.length === 1) {
                            recurrDays.push(dayMap.get(days[0]))
                        } else if (days.length > 1) {
                            days.forEach(day => {
                                recurrDays.push(dayMap.get(day))
                            })
                        }
                    } else {
                        recurr = "Dialy"
                    }
                    if ('date' in calendarObj.start) {
                        allDay = true;
                        startD = moment(calendarObj.start.date).format('YYYY-MM-DD').toString()
                        endD = moment(calendarObj.start.date).format('YYYY-MM-DD').toString()
                    } else {
                        allDay = false;
                        startD = moment(calendarObj.start.dateTime).format('YYYY-MM-DD').toString()
                        endD = moment(calendarObj.end.dateTime).format('YYYY-MM-DD').toString()
                        startT = moment(calendarObj.start.dateTime).format('HH:mm')
                        endT = moment(calendarObj.end.dateTime).format('HH:mm')
                    }
                    let data = {
                        id: calendarObj.id,
                        name: calendarObj.summary,
                        location: calendarObj.location,
                        description: calendarObj.description,
                        startDate: startD,
                        endDate: endD,
                        color: "primary",
                        allDay: allDay,
                        timeZone: calendarObj.start.timeZone,
                        organizer: calendarObj.organizer.email,
                        recurrence: recurr,
                        startTime: startT,
                        endTime: endT,
                        parentEventId: calendarObj.recurringEventId,
                        recurrenceDays: recurrDays,
                        attendees: participants
                    }

                    commit('setCalendarItems', data)

                })
            } else {
                recurr = 'Does Not Repeat'
                if ('date' in calendarObj.start) {
                    allDay = true;
                    startD = moment(calendarObj.start.date).format('YYYY-MM-DD').toString()
                    endD = moment(calendarObj.start.date).format('YYYY-MM-DD').toString()
                } else {
                    allDay = false
                    startD = moment(calendarObj.start.dateTime).format('YYYY-MM-DD').toString()
                    endD = moment(calendarObj.end.dateTime).format('YYYY-MM-DD').toString()
                    startT = moment(calendarObj.start.dateTime).format('HH:mm')
                    endT = moment(calendarObj.end.dateTime).format('HH:mm')
                }
                let data = {
                    id: calendarObj.id,
                    name: calendarObj.summary,
                    location: calendarObj.location,
                    description: calendarObj.description,
                    startDate: startD,
                    endDate: endD,
                    color: "primary",
                    allDay: allDay,
                    timeZone: calendarObj.start.timeZone,
                    organizer: calendarObj.organizer.email,
                    recurrence: recurr,
                    startTime: startT,
                    endTime: endT,
                    parentEventId: null,
                    recurrenceDays: recurrDays,
                    attendees: participants
                };
                commit('setCalendarItems', data)
            }
        },

        addEvent({ dispatch, getters }, event) {
            console.log('Event', event)
            var attendants = [], recurrence;
            var meetingStart = {}, meetingEnd = {}
            if (event.attendees.length > 1) {
                event.attendees.forEach((attend) => {
                    console.log(attend);
                    let data = {
                        email: attend,
                        comment: "Interpreter",
                    };
                    attendants.push(data);
                });
                console.log(attendants);
            }

            if (event.allDay) {
                // All day event check
                // If Event is ALL day only Date is to be provided. Timestamp is not required.
                console.log('ALL day Event')
                meetingStart = {
                    date: event.startDate,
                };
                meetingEnd = {
                    date: event.startDate,
                };

                // Recurrence RRULES
                if (event.recurrence === "Daily") {
                    recurrence = "RRULE:FREQ=DAILY";
                } else if (event.recurrence === "Weekly") {
                    let dayMap = new Map();
                    dayMap.set("Monday", "MO");
                    dayMap.set("Tuesday", "TU");
                    dayMap.set("Wednesday", "WE");
                    dayMap.set("Thrusday", "TH");
                    dayMap.set("Friday", "FR");
                    let days = "";
                    let temp = [];
                    console.log("Recurrence Array", event.recurrenceDays);
                    if (event.recurrenceDays.length > 1) {
                        console.log("Recurrence Array", event.recurrenceDays);
                        event.recurrenceDays.forEach((specEvent) => {
                            temp.push(dayMap.get(specEvent));
                        });
                        days = temp.toString();
                    } else {
                        days = dayMap.get(event.recurrenceDays[0]);
                        console.log(days);
                    }
                    recurrence = "RRULE:FREQ=WEEKLY;BYDAY=" + days;
                    console.log("Recurrence Rule", recurrence);
                } else if (event.recurrence === "Every WeekDay") {
                    recurrence = "RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR";
                } else {
                    recurrence = "NA";
                }
                console.log(meetingStart);
            } else if (
                !event.allDay &&
                event.recurrence !== "Does Not Repeat"
            ) {
                console.log('Not a All Day but a recurring event', event)
                meetingStart = {
                    dateTime: new Date(
                        event.startDate + "T" + event.startTime + ":00-05:00"
                    ),
                    timeZone: event.timeZone,
                },
                    meetingEnd = {
                        dateTime: new Date(
                            event.startDate + "T" + event.endTime + ":00-05:00"
                        ),
                        timeZone: event.timeZone,
                    };

                // Recurrence RRULES
                if (event.recurrence === "Daily") {
                    recurrence = "RRULE:FREQ=DAILY";
                } else if (event.recurrence === "Weekly") {
                    let dayMap = new Map();
                    dayMap.set("Monday", "MO");
                    dayMap.set("Tuesday", "TU");
                    dayMap.set("Wednesday", "WE");
                    dayMap.set("Thrusday", "TH");
                    dayMap.set("Friday", "FR");
                    let days = "";
                    let temp = [];
                    if (event.recurrenceDays.length > 1) {
                        event.recurrenceDays.forEach((specEvent) => {
                            temp.push(dayMap.get(specEvent));
                        });
                        days = temp.toString();
                    } else {
                        days = dayMap.get(event.recurrenceDays[0]);
                    }
                    recurrence = "RRULE:FREQ=WEEKLY;BYDAY=" + days;
                } else {
                    recurrence = "RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR";
                }

            } else {
                // Not ALL Day and NOt Recurrence event
                (meetingStart = {
                    dateTime: new Date(
                        event.startDate + "T" + event.startTime + ":00-05:00"
                    ),
                    timeZone: event.timeZone,
                }),
                    (meetingEnd = {
                        dateTime: new Date(
                            event.endDate + "T" + event.endTime + ":00-05:00"
                        ),
                        timeZone: event.timeZone,
                    });
                recurrence = "NA";
            }
            var newEvent = {
                summary: event.name,
                location: event.location,
                description: event.description,
                start: meetingStart,
                end: meetingEnd,
                attendees: attendants,
                reminders: {
                    useDefault: true,
                },
            };
            if (recurrence !== "NA") {
                newEvent["recurrence"] = [recurrence];
            }
            console.log("newEvent", newEvent);
            var request = getters.getApi.client.calendar.events.insert({
                calendarId: "primary",
                resource: newEvent,
            });

            request.execute((eventResponse) => {
                console.log("Response after Adding an event", eventResponse);
                dispatch("pullCalendarItem");

            });
        },
        updateViewEventDialog({ commit }, eventObj) {
            eventObj.show = true
            commit('openViewEventDialog', eventObj)
        }
    }
}
