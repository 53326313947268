<template>
  <v-navigation-drawer color="white" clipped-left app>
    <v-app-bar flat>        
        <v-img
        src="@/assets/logo.png"
        contain
        justify="center"
        max-height="50"
        max-width="200"
        class="my-auto"
      >
      </v-img>
      
    </v-app-bar>

    <v-divider></v-divider>
    <v-list dense>
      <v-list-item to="/">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title> Home </v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-group prepend-icon="mdi-account-circle" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title> Manage </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="item in manage" :key="item.title" :to="item.link">
          <v-list-item-content>
            <v-list-item-title v-text="item.title"> </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-text="item.icon"> </v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
      </v-list-group>
      <v-divider></v-divider>

      <v-list-group prepend-icon="mdi-google-analytics" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title> Analytics </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="item in analytics"
          :key="item.title"
          :to="item.link"
        >
          <v-list-item-content>
            <v-list-item-title v-text="item.title"> </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-text="item.icon"> </v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
      </v-list-group>
      <v-divider></v-divider>
      <v-list-group prepend-icon="mdi-video" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title> Meetings </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="item in meetings" :key="item.title" :to="item.link">
          <v-list-item-content>
            <v-list-item-title v-text="item.title"> </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-text="item.icon"> </v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
      </v-list-group>
      <v-divider></v-divider>

      
      <v-list-item  @click="$store.dispatch('pullCalendarItem')" to="/scheduling" >
        <v-list-item-icon>
          <v-icon>mdi-calendar</v-icon>
        </v-list-item-icon>
        <v-list-item-title> Scheduling </v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>

      <!--<v-list-group-->
      <!--prepend-icon="mdi-account-circle"-->
      <!--no-action>-->
      <!--<template v-slot:activator>-->
      <!--<v-list-item-content>-->
      <!--<v-list-item-title>-->
      <!--Users-->
      <!--</v-list-item-title>-->
      <!--</v-list-item-content>-->
      <!--</template>-->

      <!--<v-list-item-->
      <!--v-for="child in users"-->
      <!--:key="child.title">-->
      <!--<v-list-item-content>-->
      <!--<v-list-item-title-->
      <!--v-text="child.title">-->
      <!--</v-list-item-title>-->
      <!--</v-list-item-content>-->
      <!--<v-list-item-icon>-->
      <!--<v-icon-->
      <!--v-text="child.icon">-->
      <!--</v-icon>-->
      <!--</v-list-item-icon>-->
      <!--</v-list-item>-->
      <!--<v-divider></v-divider>-->
      <!--</v-list-group>-->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "adminDrawer",
  data() {
    return {
      selectedItem: 1,
      manage: [
        {
          title: "Users",
          icon: "mdi-account-multiple-outline",
          link: "/userManagement",
        },
        {
          title: "Organization Groups",
          icon: "mdi-account-group-outline",
          link: "/groupManagement",
        },
        {
          title: "Roles",
          icon: "mdi-card-account-details-outline",
          link: "/roleManagement",
        },
        {
          title: "Kiosks",
          icon: "mdi-presentation-play",
          link: "/kioskManagement",
        },
      ],
      announcements: [],
      analytics: [
        {
          title: "Kiosk",
          icon: "mdi-presentation-play",
          link: "/kioskAnalytics",
        },
        {
          title: "User",
          icon: "mdi-account-multiple-outline",
          link: "/userAnalytics",
        },
      ],
      meetings: [
        {
          title: "Create",
          icon: "mdi-video-plus-outline",
          link: "/createMeeting",
        },
        { title: "Manage", icon: "mdi-playlist-edit", link: "/manageMeetings" },
      ],
    };
  },
  computed: {},
};
</script>