<template>
  <v-dialog v-model="show" width="35%" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" @click="openEditEvent" color="primary">
        mdi-pencil
      </v-icon>
    </template>
    <v-card flat width="100%" class="overflow-hidden">
      <v-card-title> Edit Event </v-card-title>
      <v-card-text class="overflow-y-auto">
        <v-form>
          <v-text-field
            label="Meeting Title"
            v-model="eventData.name"
            required
          ></v-text-field>
          <v-text-field
            label="Location"
            v-model="eventData.location"
            prepend-icon="mdi-map-marker"
            required
          ></v-text-field>
          <v-text-field
            label="Description"
            v-model="eventData.description"
            prepend-icon="mdi-calendar-text-outline"
          ></v-text-field>

          <v-row>
            <v-col cols="3">
              <v-checkbox
                v-model="eventData.allDay"
                label="All Day"
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-select
                :items="options"
                attach
                v-model="eventData.recurrence"
              ></v-select>
            </v-col>
            <v-col cols="4" v-if="eventData.recurrence === 'Weekly'">
              <v-select
                :items="weekList"
                attach
                multiple
                v-model="eventData.recurrenceDays"
                label="Select Day"
              ></v-select>
            </v-col>
          </v-row>
          <!-- If ALL DAY checkbox is ticked -->
          <v-row v-if="eventData.allDay">
            <v-col cols="3">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="eventData.startDate"
                    label="Effective Date"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="eventData.startDate" scrollable>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <!-- Not a All day event but recurrence event -->
          <v-row
            v-if="
              eventData.recurrence !== 'Does Not Repeat' && !eventData.allDay
            "
          >
            <v-col cols="4">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="eventData.startDate"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="eventData.startDate" scrollable>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-dialog
                ref="dialog1"
                v-model="modal1"
                :return-value.sync="eventData.startTime"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="eventData.startTime"
                    label="Start Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="modal1"
                  v-model="eventData.startTime"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal1 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog1.save(eventData.startTime)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-col cols="4">
              <v-dialog
                ref="dialog"
                v-model="modal2"
                :return-value.sync="eventData.endTime"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="eventData.endTime"
                    label="End Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="modal2"
                  v-model="eventData.endTime"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(eventData.endTime)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <!-- Not a ALL Day event and not recurrence event -->
          <v-row
            v-if="
              !eventData.allDay && eventData.recurrence === 'Does Not Repeat'
            "
          >
            <v-col cols="8">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="eventData.startDate"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="eventData.startDate" scrollable>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-dialog
                ref="dialog1"
                v-model="modal1"
                :return-value.sync="eventData.startTime"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="eventData.startTime"
                    label="Start Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="modal1"
                  v-model="eventData.startTime"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal1 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog1.save(eventData.startTime)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-col cols="8">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="eventData.endDate"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="eventData.endDate" scrollable>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-dialog
                ref="dialog"
                v-model="modal2"
                :return-value.sync="eventData.endTime"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="eventData.endTime"
                    label="End Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="modal2"
                  v-model="eventData.endTime"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(eventData.endTime)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-icon>mdi-video</v-icon>
          <v-btn color="primary" class="mx-3 my-2"> Add Clara Connect </v-btn>
          <v-autocomplete
            v-if="!eventData.allDay"
            label="Time Zone"
            :items="timeZones"
            v-model="eventData.timeZone"
          ></v-autocomplete>
          <v-combobox
            v-model="eventData.attendees"
            prepend-icon="mdi-account-multiple"
            chips
            multiple
          ></v-combobox>
          <!-- <v-text-field label="Attendees Email Id" prepend-icon="mdi-account-multiple"></v-text-field> -->
          <v-btn color="primary" tile class="mx-5" @click="editEvent()">
            Edit
          </v-btn>
          <v-btn color="primary" tile @click="show = !show"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-dialog v-model="confirmation" width="15%">
      <v-card>
        <v-card-title class="justify-center">
          Edit Event Confirmation
        </v-card-title>
        <v-card-text class="justify-center">
          <v-radio-group v-model="selection">
            <v-radio label="Edit Current Event" value="single"></v-radio>
            <v-radio label="Edit All Events" value="recurrsive"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn @click="editRecurrsiveEvent()" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import addEvent from "./addEvent.vue";
export default {
  components: { addEvent },
  name: "addEvent",
  computed: {
    ...mapGetters(["getApi", "getEvent"]),
    eventData() {
      return this.getEvent;
    },
  },
  data() {
    return {
      show: null,
      timeZones: ["America/New_York", "America/Los_Angeles", "America/Phoenix"],
      modal2: false,
      modal1: false,
      options: ["Does Not Repeat", "Daily", "Weekly", "Every WeekDay"],
      meetingStart: null,
      meetingEnd: null,
      weekList: ["Monday", "Tuesday", "Wednesday", "Thrusday", "Friday"],
      recurrence: "",
      event: {},
      confirmation: false,
      selection: "",
    };
  },
  methods: {
    editEvent() {
      console.log(this.eventData);
      if (this.eventData.recurrence !== "Does Not Repeat") {
        this.confirmation = true;
      } else {
        this.editSingleEvent();
      }
    },
    editRecurrsiveEvent() {
      console.log("Editing Recurrsive Event : ", this.eventData);
      this.confirmation = false;
      this.show = false;
      if (this.selection === "single") {
        this.editSingleEvent();
      } else {
        // Handle Recurrsive Events Here
        console.log("Recussive Event", this.eventData.parentEventId);
        if (this.eventData.parentEventId === null) {
          //Single Event => Recurrsive Event
          this.$store.dispatch("addEvent", this.eventData).then(() => {
            this.getApi.client.calendar.events
              .delete({
                calendarId: "shubham@arsconnect.com",
                eventId: this.eventData.id,
                sendUpdates: "none",
              }).then(() => {
                this.$store.dispatch("pullCalendarItem");
              })
          });
        } else {
          this.getApi.client.calendar.events
            .get({
              calendarId: "shubham@arsconnect.com",
              eventId: this.eventData.parentEventId,
            })
            .then(() => {
              console.log("Event Data", this.eventData);
              this.getApi.client.calendar.events
                .update({
                  calendarId: "shubham@arsconnect.com",
                  eventId: this.eventData.parentEventId,
                  resource: {
                    end: {
                      date: this.eventData.endDate,
                    },
                    start: {
                      date: this.eventData.startDate,
                    },
                    summary: this.eventData.name,
                    location: this.eventData.location,
                    description: this.eventData.description,
                  },
                })
                .then(() => {
                  console.log("Event from Edit Event", this.eventData);
                  this.$store.dispatch("addEvent", this.eventData);
                });
            });
        }
      }
    },
    editSingleEvent() {
      this.getApi.client.calendar.events
        .update({
          calendarId: "shubham@arsconnect.com",
          eventId: this.eventData.id,
          resource: {
            end: {
              date: this.eventData.endDate,
            },
            start: {
              date: this.eventData.startDate,
            },
            summary: this.eventData.name,
            location: this.eventData.location,
            description: this.eventData.description,
          },
        })
        .then(() => {
          this.show = false;
        });
    },
    openEditEvent() {
      this.$store.commit("setViewEvent", false);
      this.show = true;
      this.getApi.auth2.getAuthInstance();
    },
  }
};
</script>