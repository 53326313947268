import * as fb from "../../firebase";
//import router from "../../router";

export default {
  state:{
    allRegisteredUsers: [],
    allInvitedUsers: [],

  },
  getters:{
    allRegisteredUsers: state => state.allRegisteredUsers,
    allInvitedUsers: state => state.allInvitedUsers,
  },
  mutations:{
    setAllRegisteredUsers(state, users){
      state.allRegisteredUsers = users;
    },

    setAllInvitedUsers(state, users){
      state.allInvitedUsers = users;
    }
  },
  actions:{
    getAllRegisteredUsers({commit}){
      return new Promise((resolve, reject) => {
        fb.userCollection.onSnapshot(snapshot => {
          if(snapshot.empty) console.log('Empty user collection');
          commit('setAllRegisteredUsers', snapshot.docs);
          resolve('Fetched all Invited Users in collection');
        }, error => {
          reject(error.message);
        })

      })
    },
    getAllInvitedUsers({commit}){
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection.onSnapshot(snapshot => {
          if(snapshot.empty) console.log('Empty user collection');
          commit('setAllInvitedUsers', snapshot.docs);
          resolve('Fetched all Invited Users in collection');
        }, error => {
          reject(error.message);
        })

      })
    },
    updateRegisteredUserInformation({state}, userData){
      return new Promise((resolve, reject) => {
        fb.userCollection.doc(userData.id).update({
          name: userData.name,
          userRole: userData.userRole,
          email: userData.email,
          assignedKiosks: userData.assignedKiosks
        })
          .then(() =>{
            resolve('User Information updated successfully.')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    deleteRegisteredUser({state}, userId){
      return new Promise((resolve, reject) => {
        fb.userCollection.doc(userId).delete()
          .then(() => {
            resolve('User deleted successfully')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    updateInvitedUserInformation({state}, userData){
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection.doc(userData.id).update({
          name: userData.name,
          userRole: userData.userRole,
          email: userData.email,
          assignedKiosks: userData.assignedKiosks
        })
          .then(() =>{
            resolve('User Information updated successfully.')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    deleteInvitedUser({state}, userId){
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection.doc(userId).delete()
          .then(() => {
            resolve('User deleted successfully')
          })
          .catch(error => {
            reject(error.message);
          })
      })
    }
  }
}