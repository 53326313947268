<template>
  <v-dialog v-model="show" width="35%" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="primary">
        <v-icon left> mdi-plus </v-icon>
        Add Event
      </v-btn>
    </template>
    <v-card flat width="100%" class="overflow-hidden">
      <v-card-title> Add Event </v-card-title>
      <v-card-text class="overflow-y-auto">
        <v-form>
          <v-text-field
            label="Meeting Title"
            v-model="event.name"
            required
          ></v-text-field>
          <v-text-field
            label="Location"
            v-model="event.location"
            prepend-icon="mdi-map-marker"
            required
          ></v-text-field>
          <v-text-field
            label="Description"
            v-model="event.description"
            prepend-icon="mdi-calendar-text-outline"
          ></v-text-field>

          <v-row>
            <v-col cols="3">
              <v-checkbox v-model="event.allDay" label="All Day"></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-select
                :items="options"
                attach
                v-model="event.recurrence"
              ></v-select>
            </v-col>
            <v-col cols="4" v-if="event.recurrence === 'Weekly'">
              <v-select
                :items="weekList"
                attach
                multiple
                v-model="event.recurrenceDays"
                label="Select Day"
              ></v-select>
            </v-col>
          </v-row>
          <!-- If ALL DAY checkbox is ticked -->
          <v-row v-if="event.allDay">
            <v-col cols="3">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="event.startDate"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="event.startDate" scrollable>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <!-- Not a All day event but recurrence event -->
          <v-row v-if="event.recurrence !== 'Does Not Repeat' && !event.allDay">
            <v-col cols="4">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="event.startDate"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="event.startDate" scrollable>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-dialog
                ref="dialog1"
                v-model="modal1"
                :return-value.sync="event.startTime"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="event.startTime"
                    label="Start Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="modal1"
                  v-model="event.startTime"
                  ampm-in-title
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal1 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog1.save(event.startTime)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-col cols="4">
              <v-dialog
                ref="dialog"
                v-model="modal2"
                :return-value.sync="event.endTime"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="event.endTime"
                    label="End Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker v-if="modal2" v-model="event.endTime" full-width>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(event.endTime)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <!-- Not a ALL Day event and not recurrence event -->
          <v-row v-if="!event.allDay && event.recurrence === 'Does Not Repeat'">
            <v-col cols="8">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="event.startDate"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="event.startDate" scrollable>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-dialog
                ref="dialog1"
                v-model="modal1"
                :return-value.sync="event.startTime"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="event.startTime"
                    label="Start Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="modal1"
                  v-model="event.startTime"
                  ampm-in-title
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal1 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog1.save(event.startTime)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-col cols="8">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="event.endDate"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="event.endDate" scrollable>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-dialog
                ref="dialog"
                v-model="modal2"
                :return-value.sync="event.endTime"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="event.endTime"
                    label="End Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker v-if="modal2" v-model="event.endTime" full-width>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(event.endTime)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-icon>mdi-video</v-icon>
          <v-btn color="primary" class="mx-3 my-2" @click="requestLink()">
            Add Clara Connect
          </v-btn>
          <v-progress-circular
            v-if="getLink"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <span v-if="!getLink"> {{ meetUrl }} </span>
          <v-autocomplete
            label="Time Zone"
            :items="timeZones"
            v-model="event.timeZone"
            v-if="!event.allDay"
          ></v-autocomplete>
          <v-combobox
            v-model="event.attendees"
            prepend-icon="mdi-account-multiple"
            chips
            multiple
          ></v-combobox>
          <!-- <v-text-field label="Attendees Email Id" prepend-icon="mdi-account-multiple"></v-text-field> -->
          <v-btn color="primary" tile class="mx-5" @click="addEvent()">
            Submit
          </v-btn>
          <v-btn color="primary" tile @click="show = !show"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "addEvent",
  computed: {
    ...mapGetters(["getApi", "getEvent"]),
  },
  data() {
    return {
      show: null,
      timeZones: ["America/New_York", "America/Los_Angeles", "America/Phoenix"],
      modal2: false,
      modal1: false,
      options: ["Does Not Repeat", "Daily", "Weekly", "Every WeekDay"],
      meetingStart: null,
      meetingEnd: null,
      weekList: ["Monday", "Tuesday", "Wednesday", "Thrusday", "Friday"],
      recurrence: "",
      event: {
        recurrence: "Does Not Repeat",
      },
      getLink: false,
      meetUrl: "",
    };
  },
  methods: {
    // Added for Temporary
    requestLink() {
      this.getLink = true;
      setTimeout(() => {
        console.log("Am I being called?");
        this.meetUrl = "https://clara.arsconnect.com/8bc89de2c0e";
        this.getLink = false;
      }, 2000);
    },
    addEvent() {
      console.log('Event from Add Event', this.event)
      this.$store.dispatch("addEvent", this.event).then(() => {
        this.show = false;
      });
    },
  },
};
</script>