<template >
  <v-dialog
    v-model="getViewEvent"
    width="25%"
    @click:outside="$store.commit('setViewEvent', false)"
  >
    <v-card elevation="0" width="100%">
      <v-card-title>
        Meeting Details
        <v-spacer></v-spacer>
        <edit-event></edit-event>
        <v-icon color="primary" class="ml-2" @click="deleteEvent"
          >mdi-delete</v-icon
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-list two-line>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary">mdi-format-title</v-icon></v-list-item-icon
          >
          <v-list-item-content>
            <v-list-item-title> {{ getEvent.name }}</v-list-item-title>
            <v-list-item-subtitle>
              <span>{{ getEvent.start | formatDate }}</span>
              <span v-if="getEvent.allDay"> - All Day </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary">mdi-email</v-icon></v-list-item-icon
          >
          <v-list-item-content>
            <v-list-item-title>{{ getEvent.organizer }}</v-list-item-title>

            <v-list-item-subtitle>Organizer's Email</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!getEvent.allDay">
          <v-list-item-icon>
            <v-icon color="primary">mdi-clock-start</v-icon></v-list-item-icon
          >
          <v-list-item-content>
            <v-list-item-title>{{ getEvent.startTime }}</v-list-item-title>
            <v-list-item-subtitle>Start Time</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!getEvent.allDay">
          <v-list-item-icon>
            <v-icon color="primary">mdi-clock-end</v-icon></v-list-item-icon
          >
          <v-list-item-content>
            <v-list-item-title>{{ getEvent.endTime }}</v-list-item-title>
            <v-list-item-subtitle>End Time</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import editEvent from "./editEvent.vue";
import moment from "moment";
export default {
  components: { editEvent },
  name: "viewEvent",
  data() {
    return {
      show: null,
      event: {},
    };
  },
  computed: {
    ...mapGetters(["getViewEvent", "getEvent", "getApi", "getCalenderItems"]),
  },
  methods: {
    deleteEvent() {
      console.log("Event object on delete request", this.getEvent);
      if (this.getEvent.recurrence === null || this.getEvent.recurrence === 'Does Not Repeat') {
        //delete single event

        this.getApi.client.calendar.events
          .delete({
            calendarId: "shubham@arsconnect.com",
            eventId: this.getEvent.id,
            sendUpdates: "none",
          })
          .then(() => {
            this.$store.commit("setViewEvent", false);
            this.$store.dispatch("pullCalendarItem");
          });
      } else {
        //delete recurrence event
        this.getApi.client.calendar.events
          .instances({
            calendarId: "shubham@arsconnect.com",
            eventId: this.getEvent.id,
          })
          .then((response) => {
            response.result.items.forEach((item) => {
              this.getApi.client.calendar.events.delete({
                calendarId: "shubham@arsconnect.com",
                eventId: item.id,
                sendUpdates: "none",
              }).then(() => {
                this.$store.commit("setViewEvent", false);
              })
            });
          });
      }
    },
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("dddd, MMMM DD");
    },
    formatTime(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("h:mm A");
    },
  },
};
</script>