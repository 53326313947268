<template>
  <v-navigation-drawer color="green" absolute clipped  right>

  </v-navigation-drawer>
  
</template>

<script>
export default {
  name: "calendarDrawer.vue",
};
</script>